import React from 'react'

import * as Styles from "../styles";

import MobileAccordion from './MobileAccordion';

import { Trans, useTranslation } from 'react-i18next';


export const SecurityTips = [
  {
    title: 'security.TIPS.title',
    text: 'security.TIPS.description'
  },
  {
    title: 'security.Pharming.title',
    text: 'security.Pharming.description'
  },
  {
    title: 'security.Avoid.title',
    text: 'security.Avoid.description'
  },
  {
    title: 'security.investing.title',
    text: 'security.investing.description'
  },
  {
    title: 'security.Lock.title',
    text: 'security.Lock.description'
  },
  {
    title: 'security.Update.title',
    text: 'security.Update.description'
  },
  {
    title: 'security.apps.title',
    text: 'security.apps.description'
  },
  {
    title: 'security.wifi.title',
    text: 'security.wifi.description'
  },
  {
    title: 'security.passwords.title',
    text: 'security.passwords.description'
  },
]

interface ItemsProps {
  element: {
  title: string;
  text: string;
  };
}

const Tips = ({element}: ItemsProps ) => {
  const { t } = useTranslation();

  const { title, text} = element;

  return (
    <>
      <Styles.SubHeadings> 
        <Trans i18nKey={title}/>
      </Styles.SubHeadings>
      <Styles.TextContentParagraphs>
        {t(text)}
      </Styles.TextContentParagraphs>
    </>
  );
};

const SecurityPage = () => {
  return (
    <>
      <Styles.ContentWrapper> 
        {SecurityTips.map((item)=> (
          <Tips element={item} key={item.title} />
      ))}
      </Styles.ContentWrapper>
      <Styles.WrapperAccordion>
      <MobileAccordion />
      </Styles.WrapperAccordion>
    </>
  );
};

export default SecurityPage;
