import styled from "styled-components";
import { rem } from 'polished';

export const SecurityWrapper = styled.div`
    display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
    padding: 30px 272px 0 272px;
    
    @media (max-width: 1023px) {
        padding: 17px 54px;
  }
`;

export const MainHeading = styled.h1`
    font-size: ${rem(48)};
    font-weight: ${(props) => props.theme.fontWeights.bold};
    line-height: ${rem(66)};
    color: ${(props) => props.theme.colors.tertiary};
    margin-top: ${rem(26)};
    padding-top: ${rem(80)};
    display: flex;
	flex-direction: column;
    align-items: flex-start;
    
    @media (max-width: 768px) {
        font-weight: ${(props) => props.theme.fontWeights.bold};
        font-size: ${rem(36)};
        line-height: ${rem(48)};
        padding-top: ${rem(48)};
  }
    
`;

export const TextContentTop = styled.p`
    font-size: ${rem(18)};
    font-weight: ${(props) => props.theme.fontWeights.semibold};
    line-height: ${rem(30)};
    margin-bottom: ${rem(35)};
    padding-top: ${rem(40)};
    color: ${(props) => props.theme.colors.tertiary};
    opacity: 0.7;
    text-align: justify;
    padding-bottom: ${rem(80)};
    
     @media (max-width: 768px) {
        padding-bottom: ${rem(48)};
        padding-top: ${rem(35)};
  }
`;

export const ContentWrapper = styled.div`
    display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
    padding: 30px 272px;

    @media (max-width: 1023px) {
        padding: 35px 54px;
  }

    @media (max-width: 768px) {
        display: none;
  }
`;

export const SubHeadings = styled.h2`
    font-weight: ${(props) => props.theme.fontWeights.normal};
    font-size: ${rem(34)};
    line-height: ${rem(46)};
    color: ${(props) => props.theme.colors.tertiary};
    padding-top: ${rem(48)};
    padding-bottom: ${rem(40)};
    
    @media (max-width: 768px) {
        font-size: ${rem(26)};
        line-height: ${rem(39)};
        padding-top: ${rem(45)};
        cursor: pointer;
  }
`;

export const TextContentParagraphs = styled.p`
    font-weight: ${(props) => props.theme.fontWeights.semibold};
    font-size: ${rem(18)};
    line-height: ${rem(30)};
    margin-bottom: ${rem(35)};
    padding-top: ${rem(40)};
    padding-bottom: ${rem(48)};
    opacity: 0.7;
    
    @media (max-width: 768px) {
        padding-top: ${rem(16)};
    }
`;

// accordion styling
export const WrapperAccordion = styled.div`
    display: none;

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        padding: 62px 54px;
  }
`;

export const AccordionList = styled.li`
    list-style-type: none;
    margin: 0;
    border-bottom: 1px solid rgba(66, 66, 66, .1);

    &:first-of-type {
        border-top: none;
        border-bottom: 1px solid rgba(66, 66, 66, .1);
    }
    &:last-of-type {
       border-bottom: none;
    }
`;

export const AccordionHeading = styled.h3`
    font-size: ${rem(26)};
    line-height: ${rem(39)};
    font-weight: ${(props) => props.theme.fontWeights.normal};
    color: ${(props) => props.theme.colors.tertiary};
    padding-bottom: ${rem(25)};
    padding-top: ${rem(15)};
    text-align: left;
`;

export const AccordionText = styled.p`
    font-size: ${rem(18)};
    line-height: ${rem(30)};
    font-weight: ${(props) => props.theme.fontWeights.semibold};
    color: ${(props) => props.theme.colors.tertiary};
    padding-bottom: ${rem(20)};
    opacity: 0.7;
`;

export const AccordionBtn = styled.button`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${(props) => props.theme.colors.white};
    cursor: pointer;
    width: 100%;
    padding-bottom: ${rem(37)};
    padding-top: ${rem(20)};
`;