import React from "react";
import * as Styles from "../styles";

import { Trans, useTranslation } from 'react-i18next';

import ColapseImage from "images/ColapseImage.svg";
import ExpandImage from "images/ExpandImage.svg";

interface AccordionProps {
    element: {
        title: string;
        text: string;
    },
    active: boolean;
    onToggle: () => void ;
    }

const AccordionItem = ({ element, active, onToggle }: AccordionProps) => {
  const { t } = useTranslation();
  const { title, text } = element;


  return (
    <Styles.AccordionList className={`accordion_item ${active ? "active" : ""}`}>
      <Styles.AccordionBtn onClick={onToggle}>
        <Styles.AccordionHeading>
          <Trans i18nKey={title}/>
        </Styles.AccordionHeading>
        <span><img src={active ? ColapseImage : ExpandImage} alt="" /></span>
      </Styles.AccordionBtn>
        {active && <Styles.AccordionText>
          {t(text)}
        </Styles.AccordionText>}
    </Styles.AccordionList>
  );
};

export default AccordionItem;
