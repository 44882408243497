import { useState } from "react";
import React from "react";

import { SecurityTips } from "./SecurityPage"
import AccordionItem from "./AccordionItem";


const MobileAccordion = () => {
  const [activeAccordionItem, setActiveAccordionItem] = useState(null);

  const handleToggle = (index: number) => {
    if (activeAccordionItem === index) {
      return setActiveAccordionItem(null);
    }
    setActiveAccordionItem(index);
  };
  
  return (
    <ul>
      {SecurityTips.map((element, index) => (
        <AccordionItem
          onToggle={() => handleToggle(index)}
          active={activeAccordionItem === index}
          key={index} 
          element={element}
        />
      ))}
    </ul>
  );
};

export default MobileAccordion;
