import React from 'react';

import Hero from './components/Hero';
import SecurityPage from './components/SecurityPage';

const Security = () => {
  return (
    <>
      <Hero />
      <SecurityPage />
    </>
  );
};

export default Security;
