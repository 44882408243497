import React from 'react';
import * as Styles from "../styles";

import PageHero from 'components/PageHero';

import { Trans, useTranslation } from 'react-i18next';
import NavigationColor from 'utils/constants/NavigationColor';


const Hero = () => {
  const { t } = useTranslation();

  return (
    <PageHero navigationColorTheme={NavigationColor.DARK} >
      <Styles.SecurityWrapper>
        <Styles.MainHeading>
          <Trans i18nKey={"home.Header.Security"} />
        </Styles.MainHeading>
        <Styles.TextContentTop>
          {t('security.ATF.Description')}
        </Styles.TextContentTop>
      </Styles.SecurityWrapper>
    </PageHero>
  );
};

export default Hero;
